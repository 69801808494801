import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Asset, AssetDTO, AssetTalentImages } from '@no-kno/core/models/asset.model';
import { AuthorizationService } from '@no-kno/modules/restricted/services/authorization.service';
import { DataService } from '@no-kno/modules/restricted/services/data.service';


import { ConfirmDialogComponent } from '../../dialogs/confirm/confirm.component';
import { Observable, firstValueFrom, map, of, startWith, switchMap } from 'rxjs';

import tooltips from '@no-kno/modules/talent/data/tooltips.json';
import { Tag, TagDTO } from '@no-kno/core/models/tag.model';
import { UntypedFormControl } from '@angular/forms';
import { PreviewService } from '@no-kno/core/services/preview.service';
import { TalentSelected } from '@no-kno/core/models/talent.model';
import { TalentSelectionService } from '@no-kno/modules/restricted/services/talent-selection.service';

@Component({
  selector: 'no-kno-display-asset-data',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayAssetDataComponent {

  loading = false;
  edit!: boolean;
  readonly tooltips = tooltips;
  asset!: Asset| null;
  tags: Tag[] = [];
  
  filteredTags: Observable<Tag[]>;
  searchTagControl = new UntypedFormControl('');
  
  @Output() closed = new EventEmitter<boolean>();
  @Output() deleted = new EventEmitter<string>();
  @Output() changed = new EventEmitter<{ id: string; data: Partial<AssetDTO> }>();
  @Input() viewContext: 'assets' | 'talents' = 'assets';

  @Input() set item(v: Asset | null) {
    this.asset = v;

    if(this.asset) {
      this.edit = false;
      if (this.asset.talentImages?.length == 0 ?? true) {
        this.loading = true;
        const sub = this.dataService.getAssetById(this.asset.id as string).subscribe({ 
          next: t => {
            if(this.asset && t){
              this.asset.talentImages= t.talentImages;
              this.asset.regions = t.regions;
              this.asset.viewerUrl = t.viewerUrl;
              this.asset.tags = t.tags;

              this.tags = [...t.tags];
            }
          }, 
          error: err => {
            console.log(err);
            sub.unsubscribe();
          },
          complete: () => {
            console.log("got asset complete");
            this.loading = false;
            this.detector.markForCheck();
            sub.unsubscribe();
          }
        })
      }
    }
  }

  filterAndSortTagResults = (tags: Tag[]): Tag[] => {
    const newTags = [];
    for(const tag of tags) {
      if(!this.tags.find(t => t.id === tag.id)) {
        newTags.push(tag);
      }
    }
    newTags.sort((a, b) => a.name.localeCompare(b.name));

    return newTags;
  }


  constructor(
    private dialog: MatDialog,
    private detector: ChangeDetectorRef,
    public authorization: AuthorizationService,
    private dataService: DataService,
    private previewService: PreviewService,
    private talentSelectionService: TalentSelectionService
  ) {

    this.filteredTags = this.searchTagControl.valueChanges.pipe(
      startWith(''),
      switchMap(searchTerm => searchTerm.length > 0 ? this.dataService.searchTags(searchTerm) : of([] as Tag[])),
      map(tags => this.filterAndSortTagResults(tags))
    );
  }

  displayFn(tag: Tag): string {
    return tag && tag.name ? tag.name : '';
  }

  onTagSelected(tag:Tag): void {
    console.log(`tag selected: ${tag.id}`);
    this.tags.unshift(tag);
    this.searchTagControl.setValue('');
  }

  async save(): Promise<void> {
    const tags = this.tags.map(t => ({ customer_id: t.customerId, name: t.name })) as unknown as TagDTO[];
    const data = {
      tags: tags,
    } as Partial<AssetDTO>;

    if (this.asset?.id) {
      const reference = this.dialog.open(ConfirmDialogComponent, {
        width: '400px',
        data: {
          title: 'Confirm',
          text: 'Do you want to save changes?'
        },
        disableClose: true
      });

      const confirm = await firstValueFrom(reference.afterClosed());
      if(confirm){
        this.loading = true
        this.detector.markForCheck();

        this.changed.emit({ id: this.asset.id, data });
      }
    }
  }


  onClose(): void {
    this.closed.emit(true);
  }

  onCancel(): void {
    this.reset();
    this.detector.markForCheck();
  }

  private reset(): void {
    this.edit = false;
    if (this.asset) {
      this.clearSearch(true);
    }
  }

  onImageClick(): void {
    this.previewService.requestAssetPreview({ 
      assetId: this.asset!.id as string,
    });
  }

  private getTalentImageById(id: string): AssetTalentImages | undefined {
    return this.asset?.talentImages?.find(t => t.id === id);
  }

  onTalentClick(talentId: string, talentImageId: string): void {
    const ti = this.getTalentImageById(talentImageId);
    const data:TalentSelected = {talentId, action: 'view', role: ti?.role as string};
    this.talentSelectionService.selectTalentImage(data);
  }

  private requestTalentEdit(talentId: string, talentImageId: string){
    const ti = this.getTalentImageById(talentImageId);
    const data:TalentSelected = {talentId, action: 'edit', role: ti?.role as string};
    this.talentSelectionService.selectTalentImage(data);
  }

  onEditTalentClick(event: Event, talentId: string, talentImageId: string): void {
    event.stopPropagation();
    this.requestTalentEdit(talentId, talentImageId);
  }

  onTalentTrackerClick(event: Event, talentId: string, talentImageId: string): void {
    event.stopPropagation();
    /**
     * When clicking on the thumbnail:
     * - the talent tracker view opens
     * - the talent tab appears in EDIT mode
    */
    this.requestTalentEdit(talentId, talentImageId);
    console.log(`talent tracker clicked: ${talentId} - ${talentImageId}`);

    this.previewService.requestAssetPreview({ 
      assetId: this.asset!.id as string,
      track: {
        talentId: talentId,
        assetId: this.asset!.id as string,
      }
    });
    
  }

  removeTagFromAsset(tag: Tag): void {
    this.tags = this.tags.filter(t => t.id !== tag.id);
  }

  async delete(): Promise<void> {
    if (this.asset?.id) {
      const reference = this.dialog.open(ConfirmDialogComponent, {
        width: '400px',
        data: {
          title: 'Confirm',
          text: 'Do you really want to delete this asset?'
        },
        disableClose: true
      });

      const confirm = await firstValueFrom(reference.afterClosed());
      if (confirm) {
        this.loading = true;
        this.detector.markForCheck();
        this.deleted.emit(this.asset.id);
      }
    }
  }

  clearSearch(reset = false): void {
    if(reset) {
      this.tags = this.asset?.tags || [];
    }
  }

}
