<div class="dialog">
  <div class="dialog-header">
    <h1 class="dialog-header-title" tabindex="1">Upload asset</h1>
    <button mat-flat-button mat-dialog-close type="button" class="dialog-header-close" >
      <mat-icon class="dialog-header-close-icon" >close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="dialog-content">
    <!-- add a select bound to activeMarketList and with selected element activeMarketId -->

    <mat-spinner *ngIf="activeMarketOptions.length === 0" class="dialog-content-spinner" [diameter]="24"></mat-spinner>
    <div *ngIf="activeMarketOptions.length > 0">
      <mat-label class="dialog-content-label"><p>Select the brand, market, channel and optional tags for the uploaded assets</p></mat-label>
      <!-- select active market -->
      <mat-form-field appearance="fill" class="dialog-content-search-tags">
        <mat-label>Brand and Market</mat-label>
        <mat-select [(value)]="activeMarketId" placeholder="Select brand and market"
              required (selectionChange)="onActiveMarketChange($event)"
              [disabled]="loading">
          <mat-option *ngFor="let activeMarketOption of activeMarketOptions" [value]="activeMarketOption.id">
            {{ activeMarketOption.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- select channel -->
      <mat-form-field appearance="fill" class="dialog-content-search-tags">
        <mat-label>Channel</mat-label>
        <mat-select [(value)]="channelId" placeholder="Select channel"
          required (selectionChange)="onChannelChange($event)"
          [disabled]="loading"
          >
          <mat-option *ngFor="let channel of channelOptions" [value]="channel.id">
            {{ channel.name }}
          </mat-option>
        </mat-select>
    </mat-form-field>
      <!-- select tags -->
      <mat-form-field appearance="fill" class="dialog-content-search-tags">
        <mat-label>Tag name</mat-label>
        <input type="text" matInput [formControl]="searchTagControl"
          [matAutocomplete]="auto"
          placeholder="Search tag"
          [disabled]="loading"
        >
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)='onTagSelected($event.option.value)'>
          <mat-option *ngFor="let option of filteredTags | async " [value]="option">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <ng-container *ngIf="tags.length > 0">
        <div class="card-details-content-wrapper">
          <div class="card-details-content">
            <div class="card-details-content-item-wrapper header">
              <div class="card-details-content-item">Tag name</div>
              <div class="card-details-content-item"></div>
            </div>
            <div *ngFor="let tag of tags" class="card-details-content-item-wrapper" >
              <div class="card-details-content-item tag-name">
                {{tag.name}}
              </div>
              <div class="card-details-content-item filler"></div>
              <div class="card-details-content-item">
                <button mat-flat-button class="card-details-content-item-button"
                  (click)="removeTagFromAsset(tag)"
                  [disabled]="loading"
                  >Remove</button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- upload file -->
      <no-kno-file-upload fileExt="jpg,jpeg,png,mp4" accept=".jpg,.jpeg,.png,.mp4" (changeFile)="onFileSelect($event)"></no-kno-file-upload>
      <!-- file progress -->
      <div *ngIf="filesToUpload.length > 0" class="dialog-content-file-progress">
        <div *ngFor="let fileToUpload of filesToUpload" class="dialog-content-file-progress-item">
          <div class="dialog-content-file-progress-info">
            <div class="dialog-content-file-progress-name">{{fileToUpload.name}}</div>
            <div class="dialog-content-file-progress-size">{{ (fileToUpload.size / 1024 / 1024).toFixed(2) }} MB</div>
          </div>
          <mat-progress-bar mode="determinate" [value]="fileToUpload.progress"></mat-progress-bar>
        </div>
      </div>
      <!-- <mat-label>Or you can enter a YouTube url:</mat-label>
      <mat-form-field appearance="fill" class="dialog-content-input-youtube">
        <mat-label>YouTube</mat-label>
        <input matInput [(ngModel)]="youtubeUrl" placeholder="Ex: https://www.youtube.com/watch?v=8Pj-YEQbojk">
      </mat-form-field> -->
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="actions" *ngIf="!loading">
    <button mat-flat-button class="actions-button" (click)="onClickUpload()" [disabled]="(filesToUpload.length === 0 && !youtubeUrl)">
      <span class="button-text actions-button-text">UPLOAD</span>
    </button>
  </mat-dialog-actions>

  <no-kno-loader *ngIf="loading"></no-kno-loader>

</div>
