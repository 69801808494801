<div class="content" *ngIf="items.length > 0">
  <!-- <img [src]="data.imageUrl" [alt]="title" class="content-image">-->
  <gallery
    id="talentGallery"
    class="carousel-gallery"
    loop
    bullets
    bulletPosition="bottom"
    loadingStrategy="preload"
  >
  </gallery>
</div>
