import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { catchError, map, Observable, of, throwError, delay, first, firstValueFrom } from 'rxjs';

import { format } from 'date-fns';

import { parseAssets, parseBrands, parseChannels, parseCustomCharts, parseMarkets, parsePagination, parseRegions, parseTalentImage, parseTalents, parseTags, parseUser, parseBenchmarks } from '@no-kno/core/helpers/parse.helper';
import { parseAudience, parseAudienceBelgium, convertAudienceBelgiumToDTO, parseActiveMarkets } from '@no-kno/core/helpers/parse.helper';
import { Asset, AssetCreationDTO, AssetDTO, AssetTrackingInfo, AssetTrackingInfoDTO, SignedUpload } from '@no-kno/core/models/asset.model';
import { Talent, TalentDTO, TalentImage, TalentImageDTO, TalentsFilter } from '@no-kno/core/models/talent.model';
import { Channel, ChannelDTO } from '@no-kno/core/models/channel.model';
import { Market, MarketDTO } from '@no-kno/core/models/market.model';
import { ActiveMarket, ActiveMarketDTO } from '@no-kno/core/models/active-market.model';
import { Brand, BrandDTO } from '@no-kno/core/models/brand.model';
import { Region, RegionDTO } from '@no-kno/core/models/region.model';
import { PagedData, PagedResponse } from '@no-kno/core/models/api.model';
import { CustomChart, CustomChartDTO } from '@no-kno/core/models/custom-charts.model';
import { UserInfo, UserInfoDTO } from '@no-kno/core/models/user.model';
import { Benchmark, BenchmarkDTO, BenchmarkType } from '@no-kno/core/models/benchmark.model';
import { Audience, AudienceBelgium, AudienceDTO, AudienceBelgiumDTO } from '@no-kno/core/models/audience.model';
import { ChartApiType } from '@no-kno/core/models/chart.model';

import { FilterData } from '@no-kno/modules/restricted/models/filter.model';

import { environment } from '@no-kno/env/environment';
import { Tag, TagDTO } from '@no-kno/core/models/tag.model';
import { ca, he } from 'date-fns/locale';


@Injectable()
export class ApiService {

  constructor(private http: HttpClient) {
  }


  getUserInfo(): Observable<UserInfo> {
    const url = `${environment.api.baseUrl}/users/me`;

    return this.http.get<UserInfoDTO>(url).pipe(
      map(json => parseUser(json))
    );
  }

  getAssets(filter: FilterData, sort: string, page: number, size: number, search: string = ''): Observable<PagedData<Asset[]>> {
    const url = `${environment.api.baseUrl}/assets/all/detailed`;
    const params = this.getParameters(filter, sort, page, size, search);

    return this.http.get<PagedResponse<AssetDTO>>(url, { params }).pipe(
      map(json => ({
        page: parsePagination(json),
        items: json.items.map(asset => parseAssets(asset))
      }))
    );
  }

  getAssetById(id: string): Observable<Asset> {
    const url = `${environment.api.baseUrl}/assets/${id}`;

    return this.http.get<AssetDTO>(url).pipe(
      map(asset => parseAssets(asset))
    );
  }


  getSignedUpload(identifier: string, extension: string): Observable<SignedUpload> {
    const url = `${environment.api.baseUrl}/assets/signed-upload?identifier=${identifier}&extension=${extension}`;
    return this.http.get<SignedUpload>(url);
  }

  createAsset(data: AssetCreationDTO): Observable<Asset> {
    const url = `${environment.api.baseUrl}/assets`;
    return this.http.post<AssetDTO>(url, data).pipe(
      map(json => parseAssets(json))
    );
  };

  deleteAsset(id: string): Observable<boolean> {
    const url = `${environment.api.baseUrl}/assets/${id}`;

    return this.http.delete(url, { observe: 'response' } ).pipe(
      map(response => response.status === 204)
    );
  }

  updateAsset(id: string, data: Partial<AssetDTO>): Observable<Asset> {
    const url = `${environment.api.baseUrl}/assets/${id}`;

    const tagsJoined = data.tags?.map(tag => tag.name).join(',') ?? '';

    return this.http.put<AssetDTO>(url, { tags: tagsJoined}).pipe(
      map(json => parseAssets(json))
    );
  }

  private addTalentsFilterParams(params: HttpParams, filter: TalentsFilter): HttpParams {
    if(filter.role) {
      params = params = params.append('role', filter.role);
    }
    if(filter.gender) {
      params = params = params.append('gender', filter.gender);
    }
    if(filter.age) {
      params = params = params.append('age', filter.age);
    }
    if(filter.reviewStatus) {
      params = params = params.append('review_status', filter.reviewStatus);
    }
    if(filter.facialFeatures) {
      params = params = params.append('dominant_facial_features', filter.facialFeatures);
    }
    return params;
  }

  getTalents(filter: FilterData, sort: string, page: number, size: number, extraFilter: TalentsFilter = {}): Observable<PagedData<Talent[]>> {
    const url = `${environment.api.baseUrl}/talents`;
    let params = this.getParameters(filter, sort, page, size, extraFilter?.search ?? '');
    params = this.addTalentsFilterParams(params, extraFilter);

    return this.http.get<PagedResponse<TalentDTO>>(url, { params }).pipe(
      map(json => ({
        page: parsePagination(json),
        items: json.items.map(talent => parseTalents(talent))
      }))
    );
  }

  getTalentById(id: string): Observable<Talent | null> {
    const url = `${environment.api.baseUrl}/talents/${id}`;

    return this.http.get<TalentDTO>(url).pipe(
      map(talent => parseTalents(talent)),
      catchError(() => of(null))
    );
  }

  setTalentsAttributes(id: string, data: Partial<TalentDTO>): Observable<Talent> {
    const url = `${environment.api.baseUrl}/talents/${id}`;

    return this.http.put<TalentDTO>(url, data).pipe(
      map(json => parseTalents(json))
    );
  }

  mergeTalent(fromId: string, toId: string): Observable<boolean> {
    const url = `${environment.api.baseUrl}/talents/${fromId}/merge`;

    return this.http.put(url, { merge_into_id: parseInt(toId, 10) }, { observe: 'response' } ).pipe(
      map(response => response.status === 204)
    );
  }

  deleteTalent(id: string): Observable<boolean> {
    const url = `${environment.api.baseUrl}/talents/${id}`;

    return this.http.delete(url, { observe: 'response' } ).pipe(
      map(response => response.status === 204)
    );
  }

  setTalentMainImage(id: string): Observable<TalentImage> {
    const url = `${environment.api.baseUrl}/talent_images/${id}/promote`;

    return this.http.put<TalentImageDTO>(url, null).pipe(
      map(json => parseTalentImage(json))
    );
  }

  setTalentRole(id: string, assetId: string, role: string): Observable<boolean> {
    const url = `${environment.api.baseUrl}/talent_images/talent_attributes`;
    const data = { role, asset_id: parseInt(assetId, 10), talent_id: parseInt(id, 10) };

    return this.http.put(url, data, { observe: 'response' }).pipe(
      map(response => response.status === 204)
    );
  }

  getChannels(customerId?: string): Observable<Channel[]> {
    let params = new HttpParams();
    if (customerId) {
      params = params.append('customer_id', parseInt(customerId, 10));
    }
    const url = `${environment.api.baseUrl}/channels`;
    return this.http.get<PagedResponse<ChannelDTO>>(url, { params }).pipe(
      map(json => json.items.map(channel => parseChannels(channel)))
    );
  }

  getMarkets(): Observable<Market[]> {
    const url = `${environment.api.baseUrl}/markets`;

    return this.http.get<PagedResponse<MarketDTO>>(url).pipe(
      map(json => json.items.map(market => parseMarkets(market)))
    );
  }

  getActiveMarkets(): Observable<ActiveMarket[]> {
    const url = `${environment.api.baseUrl}/active_markets?per_page=1000`;
    return this.http.get<PagedResponse<ActiveMarketDTO>>(url).pipe(
      map(json => json.items.map(market => parseActiveMarkets(market)))
    );
  }

  getBrands(): Observable<Brand[]> {
    const url = `${environment.api.baseUrl}/brands?per_page=1000`;

    return this.http.get<PagedResponse<BrandDTO>>(url).pipe(
      map(json => json.items.map(brand => parseBrands(brand)))
    );
  }

  getTags(searchString: string, markets: number[] = [], brands: number[] = []): Observable<Tag[]> {
    const url = `${environment.api.baseUrl}/tags`;
    let params = new HttpParams();
    if(searchString) {
      params = params.append('search', searchString);
    }

    if(markets.length > 0) {
      params = params.append('markets', markets.join(','));
    }
    if(brands.length > 0) {
      params = params.append('brands', brands.join(','));
    }

    return this.http.get<PagedResponse<TagDTO>>(url, {params}).pipe(
      map(json => json.items.map(tag => parseTags(tag)))
    );
  }

  getRegions(): Observable<Region[]> {
    const url = `${environment.api.baseUrl}/regions`;

    return this.http.get<PagedResponse<RegionDTO>>(url).pipe(
      map(json => json.items.map(region => parseRegions(region)))
    );
  }

  getBenchmarks(typeFilter?: string[], noknoCustomerId?: string): Observable<any> {
    /**
     * @Description Fetches benchmark data from the API. Gets only the fields id, name and type for each benchmark.
     * @param {string} emptyItemName The name of the empty item to add to the list of benchmarks.
     * @returns {Observable<any>} An observable containing the benchmark data.
     */
    const url = `${environment.api.baseUrl}/benchmarks`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (typeFilter) {
      params = params.append('filter_by_type', typeFilter.join(','));
    }
    if (noknoCustomerId) {
      params = params.append('customer_id', noknoCustomerId);
    }
    const defaultFields = 'total_number_of_pages,total_number_of_items,current_page,next_page,prev_page,items_per_page';
    headers = headers.append('X-Fields', defaultFields+','+'items{id,name,type,customer_id}');
    return this.http.get<PagedResponse<BenchmarkDTO>>(url, { headers, params }).pipe(
      // add an empty item to the list of benchmarks
      // so that the user can select 'no benchmark' in the UI
      map(json => {
        let items = json.items.map(benchmark => parseBenchmarks(benchmark));
        if (noknoCustomerId) { // delete all items where customerId is not equal to param customerId
          items = items.filter(benchmark => benchmark.customerId === noknoCustomerId);
        }
        items.unshift({ id: '', name: 'No benchmark selected', type: BenchmarkType.None } as Benchmark);
        return items;
      })
    );
  }

  getBenchmarkById(id: string): Observable<Benchmark> {
    /**
     * @description Fetches a benchmark by its id from the API.
     * @param {string} id The id of the benchmark to fetch.
     * @returns {Observable<Benchmark | null>} An observable containing the benchmark data.
     */
    if (!id || id === '') {
      throw new Error('No benchmark id provided');
    }
    const url = `${environment.api.baseUrl}/benchmarks/${id}`;
    return this.http.get<BenchmarkDTO>(url).pipe(
      map(benchmark => parseBenchmarks(benchmark))
    );
  }

  upsertBenchmark(data: BenchmarkDTO): Observable<Benchmark> {
    console.log('upsertBenchmark id: ', data.id);
    if (data.id && data.id > 0) { // data.id: number
      const url = `${environment.api.baseUrl}/benchmarks/${data.id}`;
      return this.http.put<BenchmarkDTO>(url, data).pipe(
        map(json => parseBenchmarks(json))
      );
    } else {
      const url = `${environment.api.baseUrl}/benchmarks`;
      return this.http.post<BenchmarkDTO>(url, data).pipe(
        map(json => parseBenchmarks(json))
      );
    }
  }

  deleteBenchmark(id: string): Observable<boolean> {
    const url = `${environment.api.baseUrl}/benchmarks/${id}`;

    return this.http.delete(url, { observe: 'response' } ).pipe(
      map(response => response.status === 204)
    );
  }

  getAudiences(): Observable<Audience[]> {
    /**
     * @Description Fetches audience data from the API. Gets only the fields id, name and country for each audience.
     * @returns {Observable<any>} An observable containing the audience data.
     */
    const url = `${environment.api.baseUrl}/audiences`;
    let headers = new HttpHeaders();
    const defaultFields = 'total_number_of_pages,total_number_of_items,current_page,next_page,prev_page,items_per_page';
    headers = headers.append('X-Fields', defaultFields+','+'items{id,customer_id,benchmark_id,name,country}');

    return this.http.get<PagedResponse<AudienceDTO>>(url).pipe(
      // print the results to the console
      map(json => {
        console.log('getAudiences: ', json);
        return json;
      }),
      map(json => {
        const items = json.items.map(audience => parseAudience(audience));
        items.unshift({ id: '', name: 'New Audience', customerId: '', benchmarkId: '', country: '' } as Audience);
        return items;
      })
    );
  }

  getAudienceById(id: string): Observable<AudienceBelgium> {
    if (!id || id === '') {
      throw new Error('No audience id provided');
    }
    const url = `${environment.api.baseUrl}/audiences/${id}`;
    return this.http.get<AudienceBelgiumDTO>(url).pipe(
      map(audience => parseAudienceBelgium(audience))
    );
    // const dummy = {
    //   id: '1',
    //   name: 'Dummy Audience',
    //   customerId: '',
    //   benchmarkId: '',
    //   country: 'Belgium',
    //   geo_lvl1: [
    //     { id: '04000', name: 'Brussels Capital District', selected: false, intermediate: false},
    //     { id: '02000', name: 'Flemish Region', selected: false, intermediate: true},
    //     { id: '03000', name: 'Walloon Region', selected: false, intermediate: false},
    //   ],
    //   geo_lvl2: [ // belgian provinces and their NIS codes
    //     { id: '10000', name: 'Antwerpen', parentId: '02000', selected: true},
    //     { id: '20001', name: 'Vlaams-Brabant', parentId: '02000', selected: true},
    //     { id: '30000', name: 'West-Vlaanderen', parentId: '02000', selected: false},
    //     { id: '40000', name: 'Oost-Vlaanderen', parentId: '02000', selected: false},
    //     { id: '70000', name: 'Limburg', parentId: '02000', selected: false},
    //     { id: '20002', name: 'Brabant Wallon', parentId: '03000', selected: false},
    //     { id: '50000', name: 'Hainaut', parentId: '03000', selected: false},
    //     { id: '60000', name: 'Liège', parentId: '03000', selected: false},
    //     { id: '80000', name: 'Luxembourg', parentId: '03000', selected: false},
    //     { id: '90000', name: 'Namur', parentId: '03000', selected: false},
    //     { id: '04000', name: 'Brussels Capital District', parentId: '04000', selected: false},
    //   ],
    //   urban_lvl1: [
    //     { key: 'city', value: 'City', selected: false},
    //     { key: 'municipality', value: 'Municipality', selected: false},
    //   ],
    //   age_lvl3: [
    //     { key: '-18', value: '-18', selected: false},
    //     { key: '18-24', value: '18-24', selected: false},
    //     { key: '25-34', value: '25-34', selected: false},
    //     { key: '35-44', value: '35-44', selected: false},
    //     { key: '45-54', value: '45-54', selected: false},
    //     { key: '55-64', value: '55-64', selected: false},
    //     { key: '65+', value: '65+'},
    //   ],
    //   gender: [
    //     { key: 'M', value: 'Male', selected: false},
    //     { key: 'F', value: 'Female', selected: false},
    //   ],
    //   profOptions: {
    //     education: {label: 'Education', selected: false},
    //     profStatus: {label: 'Professional status', selected: true},
    //     occupation: {label: 'Occupation', selected: false},
    //     sector: {label: 'Sector', selected: false},
    //   },
    //   education: [
    //     { key: 'high', value: 'High', selected: false},
    //     { key: 'mid', value: 'Medium', selected: false},
    //     { key: 'low', value: 'Low', selected: false},
    //     { key: 'nap', value: 'Not applicable', selected: false},
    //   ],
    //   profStatus: [
    //     { key: 'worker', value: 'Worker', selected: true},
    //     { key: 'employee', value: 'Employee', selected: true},
    //     { key: 'civilservant', value: 'Civil servant', selected: false},
    //     { key: 'selfemployed', value: 'Self-employed', selected: false},
    //     { key: 'unempinact', value: 'Inactive or unemployed', selected: false},
    //   ],
    //   occupation: [
    //     { key: 'managers', value: 'Managers (ISCO 1)', selected: false},
    //     { key: 'professionals', value: 'Professionals (ISCO 2)', selected: false},
    //     { key: 'technicians', value: 'Technicians and Associate Professionals (ISCO 3)', selected: false},
    //     { key: 'clerical_support_workers', value: 'Clerical Support Workers (ISCO 4)', selected: false},
    //     { key: 'service_sales_workers', value: 'Service and Sales Workers (ISCO 5)', selected: false},
    //     { key: 'craft_trade_workers', value: 'Craft and Related Trades Workers (ISCO 7)', selected: false},
    //     { key: 'machine_operator_assemblers', value: 'Plant and Machine Operators, and Assemblers (ISCO 8)', selected: false},
    //     { key: 'elementary_occupations', value: 'Elementary Occupations (ISCO 9)', selected: false},
    //   ],
    //   sector: [
    //     { key: 'manufacturing', value: 'Manufacturing (NACEBEL C)', selected: false},
    //     { key: 'construction', value: 'Construction (NACEBEL F)', selected: false},
    //     { key: 'wholesale_retail', value: 'Wholesale and retail trade; repair of motor vehicles and motorcycles (NACEBEL G)', selected: false},
    //     { key: 'transport_storage', value: 'Transportation and storage (NACEBEL H)', selected: false},
    //     { key: 'accomm_food', value: 'Accommodation and food service activities (NACEBEL I)', selected: false},
    //     { key: 'info_communication', value: 'Information and communication (NACEBEL J)', selected: false},
    //     { key: 'financial_insurance', value: 'Financial and insurance activities (NACEBEL K)', selected: false},
    //     { key: 'professional_scientific', value: 'Professional, scientific and technical activities (NACEBEL M)', selected: false},
    //     { key: 'admin_support', value: 'Administrative and support service activities (NACEBEL N)', selected: false},
    //     { key: 'public_admin_defence', value: 'Public administration and defence; compulsory social security (NACEBEL O)', selected: false},
    //     { key: 'education', value: 'Education (NACEBEL P)', selected: false},
    //     { key: 'health_social', value: 'Human health and social work activities (NACEBEL Q)', selected: false},
    //     { key: 'arts_entertainment', value: 'Arts, entertainment and recreation (NACEBEL R)', selected: false},
    //     { key: 'other_services', value: 'Other service activities (NACEBEL S)', selected: false},
    //     { key: 'extra_territorial', value: 'Extra-territorial organizations and bodies (NACEBEL U)', selected: false},
    //   ],
    // };
    // return of(dummy as AudienceBelgium).pipe(delay(2000));
  }

  generateNormalizedArray(elementCount: number): number[] {
    const randomArray: number[] = [];

    for (let i = 0; i < elementCount; i++) {
        randomArray.push(Math.random());
    }
    // Calculate the sum of the generated random numbers
    const sum = randomArray.reduce((acc, value) => acc + value, 0);
    // Normalize the numbers so their sum equals 100
    const normalizedArray = randomArray.map(value => (value / sum) * 100);
    return normalizedArray;
  }

  upsertAudience(audience: AudienceBelgium): Observable<Audience> {
    const data = convertAudienceBelgiumToDTO(audience);
    console.log('upsertAudience: ', data);
    if (data.id && data.id > 0) { // data.id: number
      const url = `${environment.api.baseUrl}/audiences/${data.id}`;
      return this.http.put<AudienceBelgiumDTO>(url, data).pipe(
        // // print the results to the console
        // map(json => {
        //   console.log('upsertAudience returned values: ', json);
        //   return json;
        // }),
        map(json => parseAudienceBelgium(json))
      );
    } else {
      const url = `${environment.api.baseUrl}/audiences`;
      return this.http.post<AudienceBelgiumDTO>(url, data).pipe(
        // print the results to the console
        map(json => {
          console.log('upsertAudience returned values: ', json);
          return json;
        }),
        map(json => parseAudienceBelgium(json))
      );
    }
  }

  deleteAudience(id: string): Observable<boolean> {
    const url = `${environment.api.baseUrl}/audiences/${id}`;

    return this.http.delete(url, { observe: 'response' } ).pipe(
      map(response => response.status === 204)
    );
  }

  previewBenchmarkFromAudience(audience: AudienceBelgium | null): Observable<Benchmark> {
    /**
     * @description gets a calculated benchmark from an audience from the API.
     * @param {string} id The id of the benchmark to fetch.
     * @returns {Observable<Benchmark | null>} An observable containing the benchmark data.
     */
    if (!audience) {
      throw new Error('No audience provided');
    }
    const data = convertAudienceBelgiumToDTO(audience);
    const url = `${environment.api.baseUrl}/audiences/benchmark`;
    return this.http.post<any>(url, data).pipe(
      map(benchmark => parseBenchmarks(benchmark))
    );
  }

  getChart(type: string, feature: string, params: any): Observable<any> {
    /**
     * @ description Fetches chart data from the API.
     * @ param {string} type The type of the chart to fetch. Can be one of the following: distribution, benchmark, ... (part of the url)
     * @ param {string} feature The feature to fetch the chart data for: age, gender, ethncity
     * @ param {any} params The filters to use for the chart data fetch: brands, channels, date, ...
     * @ returns: {Observable<any>} An observable containing the chart data in the form of:
        {
            "cols": [
                {
                    "id": "gender",
                    "label": "Visual gender",
                    "type": "string"
                },
                {
                    "id": "percentage",
                    "label": "Bucket share",
                    "type": "number"
                }
            ],
            "rows": [
                {
                    "c": [
                        {
                            "v": "Male"
                        },
                        {
                            "v": 0.51
                        }
                    ]
                },
                {
                    "c": [
                        {
                            "v": "Female"
                        },
                        {
                            "v": 0.49
                        }
                    ]
                },
            ],
            "actual_diversity_score": 50,
            "benchmark_diversity_score": 0
        }
     */

    let url;
    if (params.benchmarkId !== '' && type === ChartApiType.Distribution) {
      url = `${environment.api.baseUrl}/charts/benchmark/${feature}`;
    } else {
      url = `${environment.api.baseUrl}/charts/${type}/${feature}`;
    }
    let searchParams = new HttpParams();
    if (params.date?.start) {
      const startDate = format(params.date.start, 'yyyy-MM-dd');
      searchParams = searchParams.append('min_date', startDate);
    }
    if (params.date?.end) {
      const endDate = format(params.date.end, 'yyyy-MM-dd');
      searchParams = searchParams.append('max_date', endDate);
    }
    if (params.brands.length > 0) {
      if( type === ChartApiType.ObjectivesDistribution) {
        // objectives charts can only be generated for one brand at a time,
        // because an active market = link between a brand and a market
        searchParams = searchParams.append('brands', params.brands[0]);
      } else {
        searchParams = searchParams.append('brands', params.brands.join(','));
      }
    }
    if (params.channels.length > 0) {
      searchParams = searchParams.append('channels', params.channels.join(','));
    }
    if (params.criterion) {
      searchParams = searchParams.append('criterion', params.criterion);
    }

    if (params.tags.length > 0) {
      searchParams = searchParams.append('tags', params.tags.join(','));
    }

    if(params.role && params.role !== 'all') {
      searchParams = searchParams.append('role', params.role);
    }

    if (params.benchmarkId && params.benchmarkId !== '') {
      searchParams = searchParams.append('benchmark_id', params.benchmarkId);
    }

    if (params.skipEmptyBins === true) {
      searchParams = searchParams.append('skip_empty_bins', 'true');
    }

    if( type === ChartApiType.ObjectivesDistribution) {
      // objectives charts can only be generated for one market at a time,
      // because an active market = link between a brand and a market
      if (params.markets.length > 0) {
        searchParams = searchParams.append('markets', params.markets[0]);
      } else {
        return throwError(() => new Error('No market selected'));
      }
    } else if (params.markets.length > 0) {
      searchParams = searchParams.append('markets', params.markets.join(','));
    }

    const res = this.http.get<any>(url, { params: searchParams });
    ///* print results to the console */
    // console.log(url, searchParams.toString());
    // res.subscribe((value) => {
    //   console.log('res value:', value);
    // }
    // );
    return res;
  }

  getCustomChart(): Observable<CustomChart[]> {
    const url = `${environment.api.baseUrl}/custom_charts`;

    return this.http.get<PagedResponse<CustomChartDTO>>(url).pipe(
      map(json => json.items.map(chart => parseCustomCharts(chart)))
    );
  }

  getAssetTrackingInfo(id: string): Observable<AssetTrackingInfo|null> {
    const url = `${environment.api.baseUrl}/assets/${id}/people-tracking`;

    return this.http.get<AssetTrackingInfoDTO>(url).pipe(
      map(info => info as AssetTrackingInfo),
      catchError(() => of(null))
    );
  }


  private getParameters(filter: FilterData, sort: string, page: number, size: number, search: string): HttpParams {
    let params = new HttpParams()
      .append('page', page)
      .append('per_page', size);

    if (sort) {
      params = params.append('sort', sort.replace(':asc', ''));
    }

    if (filter) {
      if (filter.markets.length > 0) {
        params = params.append('markets', filter.markets.join(','));
      }

      if (filter.brands.length > 0) {
        params = params.append('brands', filter.brands.join(','));
      }

      if (filter.channels.length > 0) {
        params = params.append('channel_types', filter.channels.join(','));
      }

      if (filter.tags.length > 0) {
        params = params.append('tags', filter.tags.join(','));
      }

      if (filter.date && filter.date.start && filter.date.end) {
        params = params.append('start_date', format(filter.date.start, 'yyyy-MM-dd'));
        params = params.append('end_date', format(filter.date.end, 'yyyy-MM-dd'));
      }
    }

    if(search) {
      params = params.append('search', search);
    }

    return params;
  }

}
