import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule,  ReactiveFormsModule } from '@angular/forms';

import { GoogleChartsModule } from 'angular-google-charts';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatListModule } from '@angular/material/list';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { HelpAndFeedbackFormsComponent } from './components/forms/form-send-feedback/form-send-feedback.component';
import { CookiesDialogComponent } from './components/dialogs/cookies/cookies.component';
import { SvgLogoComponent } from './components/svgs/logo/svg-logo.component';
import { DisplayAssetDataComponent } from './components/display/assets/display.component';
import { DisplayTalentDataComponent } from './components/display/talents/display.component';
import { HelpAndFeedbackDialogComponent } from './components/dialogs/help-and-feedback/help-and-feedback.component';
import { ConfirmDialogComponent } from './components/dialogs/confirm/confirm.component';
import { EditButtonsComponent } from './components/elements/edit-buttons/edit-buttons.component';
import { ReviewStatusComponent } from './components/elements/review-status/review-status.component';
import { UploadAssetFormComponent } from './components/forms/upload-asset/upload-asset.component';

import { ChannelPipe } from './pipes/channel.pipe';
import { ViewsPipe } from './pipes/views.pipe';
import { GalleryModule } from 'ng-gallery';

import { FileUploadComponent } from './components/elements/file-upload/file-upload.component';
import { FiltersSectionComponent } from './components/elements/filters-section/filters-section.component';
import { RolesPipe } from './pipes/roles.pipe';
import { EntityPreviewComponent } from './components/elements/entity-preview/entity-preview.component';
import { AssetPreviewComponent } from './components/elements/asset-preview/asset-preview.component';
import { TalentPreviewComponent } from './components/elements/talent-preview/talent-preview.component';
import { LoaderComponent } from './components/elements/loader/loader.component';
import { AssetTrackerComponent } from './components/elements/asset-tracker/asset-tracker.component';
import { TimelineCanvasComponent } from './components/elements/timeline-canvas/timeline-canvas.component';


const imports = [
  HttpClientModule,
  FormsModule,
  AngularSvgIconModule,
  GoogleChartsModule,
  GalleryModule,
  ReactiveFormsModule
];

const material = [
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatButtonModule,
  MatSnackBarModule,
  MatCardModule,
  MatTabsModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatChipsModule,
  MatDatepickerModule,
  MatListModule,
  MatNativeDateModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatDialogModule,
  MatTooltipModule,
  MatSelectModule,
  MatCheckboxModule,
  DragDropModule,
  MatMenuModule,
  MatAutocompleteModule,
  MatSidenavModule,
  MatExpansionModule,
  MatProgressBarModule,
];

const elements = [
  SvgLogoComponent,
  DisplayAssetDataComponent,
  DisplayTalentDataComponent,
  EditButtonsComponent,
  ReviewStatusComponent,
  FileUploadComponent,
  FiltersSectionComponent,
  EntityPreviewComponent,
  LoaderComponent,
  AssetTrackerComponent,
  TimelineCanvasComponent
];

const dialogs = [
  HelpAndFeedbackDialogComponent,
  HelpAndFeedbackFormsComponent,
  UploadAssetFormComponent,
  AssetPreviewComponent,
  TalentPreviewComponent,
  CookiesDialogComponent,
  ConfirmDialogComponent
];

const pipes = [
  ChannelPipe,
  ViewsPipe,
  RolesPipe
];


@NgModule({
  imports: [
    CommonModule,
    ...imports,
    ...material
  ],
  declarations: [
    ...elements,
    ...dialogs,
    ...pipes,
	],
  exports: [
    ...material,
    ...elements,
    ...dialogs,
    ...pipes,
    ...imports
  ],
  providers: [
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule {
}
