import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef, OnDestroy, OnInit, Inject } from '@angular/core';
import { Observable, Subscription, firstValueFrom, map, startWith, switchMap, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '@no-kno/modules/restricted/services/api.service';
import { TenantService } from '@no-kno/core/services/tenant.service';
import { NotificationService } from '@no-kno/modules/restricted/services/notification.service';
import { ActiveMarketOption } from '@no-kno/core/models/active-market.model';
import { DataService } from '@no-kno/modules/restricted/services/data.service';
import { Customer } from '@no-kno/core/models/customer.model';
import { Channel, ChannelType } from '@no-kno/core/models/channel.model';
import { UntypedFormControl } from '@angular/forms';
import { Tag, TagDTO } from '@no-kno/core/models/tag.model';
import { AssetDTO } from '@no-kno/core/models/asset.model';
import { AuthorizationService } from '@no-kno/modules/restricted/services/authorization.service';
import { HttpEventType } from '@angular/common/http';


import { AssetCreationDTO, AssetExtensions, SignedUpload } from '@no-kno/core/models/asset.model';

//@ts-ignore
import * as uuid from 'uuid';
import { HttpClient } from '@angular/common/http';
import { NoknoNotificationTypes } from '@no-kno/modules/restricted/models/notification.model';

export interface FileToUpload {
  name: string;
  file: File;
  size: number;
  progress: number;
}

@Component({
  selector: 'no-kno-upload-asset-form',
  templateUrl: './upload-asset.component.html',
  styleUrls: ['./upload-asset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class UploadAssetFormComponent implements OnDestroy {

  youtubeUrl = '';
  //file: File | undefined;
  filesToUpload: FileToUpload[] = [];
  loading = false;
  private subscription = new Subscription();
  activeMarketId = '';
  activeMarketOptions: ActiveMarketOption[] = [];
  channelOptions: Channel[] = [];
  channelId = '';
  customer: Customer | null = null;
  noknoCustomerId = '';
  filteredTags: Observable<Tag[]>;
  searchTagControl = new UntypedFormControl('');
  tags: Tag[] = [];

  constructor(
    private apiService: ApiService,
    private notificationService: NotificationService,
    private http: HttpClient,
    public tenantService: TenantService,
    private snackBar: MatSnackBar,
    private dialog: MatDialogRef<UploadAssetFormComponent>,
    private detector: ChangeDetectorRef,
    private dataService: DataService,
    public authorization: AuthorizationService,
  ) {
    this.filteredTags = this.searchTagControl.valueChanges.pipe(
      startWith(''),
      switchMap(searchTerm => searchTerm.length > 0 ? this.dataService.searchTags(searchTerm) : of([] as Tag[])),
      map(tags => this.filterAndSortTagResults(tags))
    );
  }

  async ngOnInit(): Promise<void> {
    const customerCode = this.tenantService.customer;
    this.customer = await firstValueFrom(this.tenantService.getCustomerData(customerCode));
    if (this.customer) {
      this.noknoCustomerId = await firstValueFrom( this.apiService.getUserInfo()?.pipe(map(userInfo => userInfo.customerId || '')) );
      const customerId = this.customer.id;
      this.channelOptions = await firstValueFrom(this.apiService.getChannels(this.noknoCustomerId));
      this.activeMarketOptions = await this.dataService.getActiveMarketOptions();
      const sessionActiveMarketId = sessionStorage.getItem('uploadDialogActiveMarketId');
      if (sessionActiveMarketId) {
        this.activeMarketId = sessionActiveMarketId;
      } else if (this.customer?.defaultActiveMarketId) {
        this.activeMarketId = this.customer.defaultActiveMarketId.toString();
      }
      const sessionChannelId = sessionStorage.getItem('uploadDialogChannelId');
      if (sessionChannelId) {
        this.channelId = sessionChannelId;
      } else if (this.customer?.defaultChannel) {
        // the defaultChannel is a channelType, so we need to find the corresponding channel
        this.channelId = this.channelOptions.find(channel => channel.type === this.customer?.defaultChannel)?.id ?? '';
      }
    }
    this.detector.markForCheck();
  }

  onActiveMarketChange(event: any): void {
    sessionStorage.setItem('uploadDialogActiveMarketId', this.activeMarketId);
  }

  onChannelChange(event: any): void {
    sessionStorage.setItem('uploadDialogChannelId', this.channelId);
  }

  filterAndSortTagResults = (tags: Tag[]): Tag[] => {
    const newTags = [];
    for(const tag of tags) {
      if(!this.tags.find(t => t.id === tag.id)) {
        newTags.push(tag);
      }
    }
    newTags.sort((a, b) => a.name.localeCompare(b.name));

    return newTags;
  };

  removeTagFromAsset(tag: Tag): void {
    this.tags = this.tags.filter(t => t.id !== tag.id);
  }

  displayFn(tag: Tag): string {
    return tag && tag.name ? tag.name : '';
  }

  onTagSelected(tag: Tag): void {
    if (!this.loading) {
      console.log(`tag selected: ${tag.id}`);
      this.tags.unshift(tag);
      this.searchTagControl.setValue('');
    }
  }

  onFileSelect(files: Array<File>) {
    /**
     * This function is called when the user selects files to upload by dragging or clicking on the file input button
     */
    console.log('files selected', files);
    // if (files.length > 0) {
    //   this.file = files[0];
    // }
    for (const file of files) {
      this.filesToUpload.push({
        name: file.name,
        file,
        size: file.size,
        progress: 0
      });
    }
  }

  private uploadFile(signedUpload: SignedUpload, fileToUpload: FileToUpload): Observable<any> {

    const multipartParams = signedUpload.fields;

    const data = new FormData();
    Object.keys(multipartParams).forEach(key => {
      data.append(key, multipartParams[key]);
    });
    // data.append('contentType', file.type);
    data.append('file', fileToUpload.file, fileToUpload.name);

    return this.http.post(signedUpload.url, data, {reportProgress: true, observe: 'events'});
  }

  private async sendDataToAPI(data: AssetCreationDTO): Promise<void> {

    const result = await firstValueFrom(this.apiService.createAsset(data)).catch((e) => {
      console.error(e);
    });

    if (result && result.id) {
      // result: Asset
      // we have a result, now get the asset id and add tags
      const tags = this.tags.map(t => ({ customer_id: t.customerId, name: t.name })) as unknown as TagDTO[];
      const assetData = {
        tags,
      } as Partial<AssetDTO>;
      const result2 = await firstValueFrom(this.apiService.updateAsset(result.id, assetData));

      console.log('Asset created', result2);
      // move this to the end if all assets have been uploaded
      // this.snackBar.open('Asset created', 'Close');
      // this.dialog.close();
      this.notificationService.sendNotification({
        subject: NoknoNotificationTypes.AssetCreated,
        payload: {
          asset: result2
        }
      });

    } else {
      this.snackBar.open('Error creating asset', 'Close');
    }
    this.detector.markForCheck();
  }



  async onClickUpload(): Promise<void> {
    /**
     * This function is called when the user clicks the upload button
     */
    this.loading = true;
    this.searchTagControl.disable();
    this.detector.markForCheck();

    // data validation checks
    if (this.activeMarketId === '' || this.channelId === '') {
      this.snackBar.open('Invalid customer settings', 'Close');
      this.dialog.close();
      this.loading = false;
      this.searchTagControl.enable();
      return;
    }

    // get channel type from channel id
    const channelTypeString = this.channelOptions.find(channel => channel.id === this.channelId)?.type ?? '';
    const channelType = channelTypeString as ChannelType;

    // loop through the filesToUpload array and upload each file
    if (this.filesToUpload.length > 0) {
      for (const fileToUpload of this.filesToUpload) {
        const identifier = uuid.v4();
        const data: AssetCreationDTO = {
          channel_types: channelType,
          active_market_id: parseInt(this.activeMarketId, 10),
          identifier,
          extension: AssetExtensions.JPEG,
          url: '',
          name: ''
        };
        data.extension = fileToUpload.name.split('.').pop()?.toLowerCase() as AssetExtensions;
        data.name = fileToUpload.name;

        const uploadForm = await firstValueFrom(this.apiService.getSignedUpload(identifier, data.extension))
          .catch((e) => {
            console.error(e);
          });

        if (!uploadForm) {
          this.snackBar.open('Error getting signed upload', 'Close');
          return;
        }
        this.subscription.add(this.uploadFile(uploadForm, fileToUpload).subscribe({
          next: (v) => {
            if (v !== null && v !== undefined) {
              //console.log('next', v);
              if (v.type === HttpEventType.UploadProgress) {
                fileToUpload.progress = Math.round(100 * v.loaded / v.total);
                this.detector.markForCheck();
              }
            }
          },
          error: (e) => {
            console.error(e);
            this.snackBar.open('Error uploading file', 'Close');
            this.loading = false;
            this.searchTagControl.enable();
          },
          complete: async () => {
            const fileUrl = `${uploadForm.url}/${uploadForm.fields.key}`;
            fileToUpload.progress = 100;
            console.log('complete', fileUrl);
            data.url = fileUrl;
            if (data.extension !== AssetExtensions.MP4) {
              data.thumbnail_url = fileUrl;
            }
            this.detector.markForCheck();
            await this.sendDataToAPI(data);
            this.closeIfAllFilesUploaded();
          }
        }));
      }

    } else if (this.youtubeUrl) {
      const identifier = uuid.v4();
      const data: AssetCreationDTO = {
        channel_types: channelType,
        active_market_id: parseInt(this.activeMarketId, 10),
        identifier,
        extension: AssetExtensions.JPEG,
        url: '',
        name: ''
      };
      data.extension = AssetExtensions.MP4;
      data.url = this.youtubeUrl;
      data.name = `${identifier}.mp4`;
      await this.sendDataToAPI(data);
      this.dialog.close();
      this.loading = false;
      this.searchTagControl.enable();
    } else {
      console.error('No file or youtube url');
      this.loading = false;
      this.searchTagControl.enable();
      this.snackBar.open('No file or youtube url', 'Close');
    }
  }

  // check if all files have been uploaded and processed by looping over the filesToUpload array
  // and checking if the progress is 100
  closeIfAllFilesUploaded(): void {
    if (this.filesToUpload.every(f => f.progress === 100)) {
      this.snackBar.open('Asset created', 'Close');
      this.dialog.close();
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
