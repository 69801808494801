import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TalentImage } from '@no-kno/core/models/talent.model';
import {
  Gallery,
  GalleryItem,
  GalleryRef,
  ImageItem,
} from 'ng-gallery';

@Component({
  selector: 'no-kno-talent-preview',
  templateUrl: './talent-preview.component.html',
  styleUrls: ['./talent-preview.component.scss'],
})
export class TalentPreviewComponent {
  @Input() data!: { images: TalentImage[]; imageIndex: number };
  galleryRef!: GalleryRef;
  items: GalleryItem[] = [];

  constructor(private detector: ChangeDetectorRef, private gallery: Gallery) {}

  ngOnInit(): void {
    this.galleryRef = this.gallery.ref('talentGallery');

    if (this.data.images.length > 0) {
      this.data.images.map((img) => {
        this.items.push(
          new ImageItem({
            src: img.imageUrl,
            thumb: img.assetThumbnailUrl,
            args: img.id,
          })
        );
      });

      this.galleryRef.load(this.items);
      this.galleryRef.set(this.data.imageIndex);

      this.detector.markForCheck();
    }
  }
}
